import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image";
import Loading from '../components/Loading';
import { Link } from 'gatsby';
import Layout from '../components/Layout';


export default function Home(){

    const data = useStaticQuery(graphql`
    query IndexQuery {
      markdownRemark(fileAbsolutePath: {regex: "/content/index/"}) {
        html
        frontmatter {
          index {
            title
            URL
            photo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    `)
    const thumbs = data.markdownRemark.frontmatter.index;
    return(
    
    <Layout title="Portfolio">
      <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} className="large-picture"></div> 
    {
        !thumbs ? <Loading/> : thumbs.map((thumb,i)=>
        <Link key={i} to={thumb.URL}>
            <Image fluid={thumb.photo.childImageSharp.fluid}
        alt={thumb.title} className="large-picture"/>
        </Link>)
    }
    </Layout>
    );
}